"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.SponsoredRewardEmailToJSON = exports.SponsoredRewardEmailFromJSONTyped = exports.SponsoredRewardEmailFromJSON = void 0;
function SponsoredRewardEmailFromJSON(json) {
    return SponsoredRewardEmailFromJSONTyped(json, false);
}
exports.SponsoredRewardEmailFromJSON = SponsoredRewardEmailFromJSON;
function SponsoredRewardEmailFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'subject': json['subject'],
        'body': json['body'],
    };
}
exports.SponsoredRewardEmailFromJSONTyped = SponsoredRewardEmailFromJSONTyped;
function SponsoredRewardEmailToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'subject': value.subject,
        'body': value.body,
    };
}
exports.SponsoredRewardEmailToJSON = SponsoredRewardEmailToJSON;
