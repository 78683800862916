"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.SponsoredRewardTypeToJSON = exports.SponsoredRewardTypeFromJSONTyped = exports.SponsoredRewardTypeFromJSON = exports.SponsoredRewardType = void 0;
/**
 * Type of reward that can be won in a sponsored competition
 * @export
 * @enum {string}
 */
var SponsoredRewardType;
(function (SponsoredRewardType) {
    SponsoredRewardType["XP"] = "XP";
    SponsoredRewardType["NFT"] = "NFT";
    SponsoredRewardType["FUEL"] = "FUEL";
    SponsoredRewardType["GIFTCARD"] = "GIFT_CARD";
    SponsoredRewardType["OTHER"] = "OTHER";
})(SponsoredRewardType = exports.SponsoredRewardType || (exports.SponsoredRewardType = {}));
function SponsoredRewardTypeFromJSON(json) {
    return SponsoredRewardTypeFromJSONTyped(json, false);
}
exports.SponsoredRewardTypeFromJSON = SponsoredRewardTypeFromJSON;
function SponsoredRewardTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.SponsoredRewardTypeFromJSONTyped = SponsoredRewardTypeFromJSONTyped;
function SponsoredRewardTypeToJSON(value) {
    return value;
}
exports.SponsoredRewardTypeToJSON = SponsoredRewardTypeToJSON;
