"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.SponsoredRewardToJSON = exports.SponsoredRewardFromJSONTyped = exports.SponsoredRewardFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function SponsoredRewardFromJSON(json) {
    return SponsoredRewardFromJSONTyped(json, false);
}
exports.SponsoredRewardFromJSON = SponsoredRewardFromJSON;
function SponsoredRewardFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'winningCriteria': (0, _1.WinningCriteriaFromJSON)(json['winningCriteria']),
        'description': json['description'],
        'rewardType': (0, _1.SponsoredRewardTypeFromJSON)(json['rewardType']),
        'amount': json['amount'],
        'nftTrackerId': !(0, runtime_1.exists)(json, 'nftTrackerId') ? undefined : json['nftTrackerId'],
        'email': (0, _1.SponsoredRewardEmailFromJSON)(json['email']),
    };
}
exports.SponsoredRewardFromJSONTyped = SponsoredRewardFromJSONTyped;
function SponsoredRewardToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'winningCriteria': (0, _1.WinningCriteriaToJSON)(value.winningCriteria),
        'description': value.description,
        'rewardType': (0, _1.SponsoredRewardTypeToJSON)(value.rewardType),
        'amount': value.amount,
        'nftTrackerId': value.nftTrackerId,
        'email': (0, _1.SponsoredRewardEmailToJSON)(value.email),
    };
}
exports.SponsoredRewardToJSON = SponsoredRewardToJSON;
