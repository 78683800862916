"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.WinningCriteriaToJSON = exports.WinningCriteriaFromJSONTyped = exports.WinningCriteriaFromJSON = exports.WinningCriteria = void 0;
/**
 * Criteria for winning a sponsored competition
 * @export
 * @enum {string}
 */
var WinningCriteria;
(function (WinningCriteria) {
    WinningCriteria["TOPGUILDXPGAINER"] = "TOP_GUILD_XP_GAINER";
    WinningCriteria["TOPOVERALLXPGAINER"] = "TOP_OVERALL_XP_GAINER";
    WinningCriteria["WINNINGGUILD"] = "WINNING_GUILD";
})(WinningCriteria = exports.WinningCriteria || (exports.WinningCriteria = {}));
function WinningCriteriaFromJSON(json) {
    return WinningCriteriaFromJSONTyped(json, false);
}
exports.WinningCriteriaFromJSON = WinningCriteriaFromJSON;
function WinningCriteriaFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.WinningCriteriaFromJSONTyped = WinningCriteriaFromJSONTyped;
function WinningCriteriaToJSON(value) {
    return value;
}
exports.WinningCriteriaToJSON = WinningCriteriaToJSON;
