"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.SponsoredGuildCompetitionToJSON = exports.SponsoredGuildCompetitionFromJSONTyped = exports.SponsoredGuildCompetitionFromJSON = void 0;
var _1 = require("./");
function SponsoredGuildCompetitionFromJSON(json) {
    return SponsoredGuildCompetitionFromJSONTyped(json, false);
}
exports.SponsoredGuildCompetitionFromJSON = SponsoredGuildCompetitionFromJSON;
function SponsoredGuildCompetitionFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'sponsorBrandName': json['sponsorBrandName'],
        'promotionImage': json['promotionImage'],
        'rewards': (json['rewards'].map(_1.SponsoredRewardFromJSON)),
        'runningMonth': json['runningMonth'],
        'runningYear': json['runningYear'],
        'description': json['description'],
        'brandUrl': json['brandUrl'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
    };
}
exports.SponsoredGuildCompetitionFromJSONTyped = SponsoredGuildCompetitionFromJSONTyped;
function SponsoredGuildCompetitionToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'sponsorBrandName': value.sponsorBrandName,
        'promotionImage': value.promotionImage,
        'rewards': (value.rewards.map(_1.SponsoredRewardToJSON)),
        'runningMonth': value.runningMonth,
        'runningYear': value.runningYear,
        'description': value.description,
        'brandUrl': value.brandUrl,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
    };
}
exports.SponsoredGuildCompetitionToJSON = SponsoredGuildCompetitionToJSON;
